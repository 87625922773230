
const host = "https://rd-owner-portal.web.app/api";

  /**
   * Make a GET call.
   * @param {String} path
   * @returns {Promise<any>}
   */
  const get = async (path, headers) => {
    return await fetch(`${host}${path}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...headers
      },
    })
    .then(response => Promise.all([response.ok, response.json()]))
    .then(([ok, body]) => handleErrors(ok, body))
  };

  function handleErrors(ok, body) {
    if (!ok) {
        throw Error(body.error.errorMessage);
    }
    return body;
}

  /**
   * Make a POST call.
   * @param {String} path
   * @param {Object} body
   * @returns {Promise<any>}
   */
  const post = async (path, body) => {
    return await fetch(`${host}${path}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...this.getHeaders()
      },
      body: JSON.stringify(body)
    }).then(response => response.json());
  };

  /**
   * Make a PUT call.
   * @param {String} path
   * @param {Object} body
   * @returns {Promise<any>}
   */
  const put = async (path, body, headers) => {
    return await fetch(`${host}${path}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...headers
      },
      body: JSON.stringify(body)
    }).then(response => response.json());
  };

  /**
   * Make a DELETE call.
   * @returns {Promise<any>}
   */
  const del = async (path, headers) => {
    return await fetch(`${host}${path}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...headers
      }
    }).then(response => response.json());
  };


class Client {
  idToken = null;

  headers = {
    'api-key': process.env.API_KEY,
    'client-id': process.env.CLIENT_ID
  }
  
  getHeaders = () => ({
    ...this.headers,
    Authorization: this.idToken
  })

  /*
  Mouln API client code
  */

  /**
   * @returns {Promise<import("rdd-shared").Summary>}
   */
  getSummary = async () => await get('/user/summary', this.getHeaders());

}

var api = new Client();

export { api } ;
