import { getState } from "./Context";

const useToast = () => {
  const [state, setState] = getState();

  /**
   * @param {Toast} toast 
   */
  const setToast = toast => {
    setState(state => ({ ...state, toast }));
  }

  const showSuccess = message => {
      setToast({message, type: 'success'})
  }

  const showError = message => {
      setToast({message, type: 'error'})
  }

  return {
    toast: state.toast,
    setToast,
    showSuccess,
    showError
  }
};

export default useToast;