//@ts-check
import React, { createContext, useState, useContext } from "react";

// Import stubs
import { summary } from '../../stubs/summary';
import { account } from '../../stubs/account';

import { State } from 'lib';

/**
 * @type {React.Context<[State, function(State):void]>}
 */

//@ts-ignore
const RDContext = createContext([{}, () => {}]);

/**
DEVELOPMENT STATE, mock data
@type {State}
*/
const devState = {
  user: {
    name: "Nicolas",
    email: "nicolas@reekanddalli.com"
  },
  data: summary,
  account: account,
  isInit: true,
  isLoading: false,
  isLoggedIn: true,
  isExpensesPopupShown: false
}

/**
PRODUCTION STATE, blank slate
@type {State}
*/
const prodState = {
  data: summary,
  account: account,
  isInit: true,
  isLoading: false,
  isLoggedIn: true,
  isExpensesPopupShown: false,
  selectedYear: 2020
}

const RDProvider = props => {
  /**
   * @type {[State, React.Dispatch<React.SetStateAction<State>>]}
   */
  const [state, setState] = useState(prodState);

  return (
    <RDContext.Provider value={[state, setState]}>
      {props.children}
    </RDContext.Provider>
  );
};

/**
 * @returns {[State, function(function (State): State):void]}
 */
const getState = () => {
  const [state, setState] = useContext(RDContext);
  // console.log(`State: ${JSON.stringify(state)}`);
  return [state, setState];
};

export { RDContext, RDProvider, getState };
