import React from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/core";

export const Header = props => (
  <div
    css={css`
      display: flex;
      flex-direction: row;
      padding: 10px;
    `}
    {...props}
  />
);
