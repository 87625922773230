import React, { useEffect, useState } from 'react';
// API
import { api } from '../../api/api';

// Hooks
import useAuth from '../../common/state/useAuth';
import useCalendar from '../../common/state/useCalendar';
import useData from '../../common/state/useData';
import useExpensesPopup from '../../common/state/usePopup';
import useSession from '../../common/state/useSession';
// Components
import { Loading } from '../../components/Loading';
import { useLocation } from 'react-router-dom';
import { CalendarWidget } from './components/Calendar';
import { Summary } from './components/Summary';
import { WelcomeBanner } from './components/WelcomeBanner';
import { ExpensesPopup } from './components/ExpensesPopup';
import { DateRangePopup } from './components/DateRangePopup';

/** @jsx jsx */
import { css, jsx } from "@emotion/core";


export default props => {
    const { user: firebaseUser, signout } = useAuth();
    const { isLoggedIn, setLoggedIn } = useSession();
    const { isExpensesPopupShown, setExpensesPopupShown } = useExpensesPopup();
    const { selectedRange } = useCalendar();
    const location = useLocation();
    const { user, setUser, properties, setProperties } = useData();
    const [loadingMessage, setLoadingMessage] = useState('');
    /*
      Fetch summary on first load.
      */
    useEffect(() => {
        console.log(`User: ${firebaseUser}`);
        if (firebaseUser && !user) {
            getSummary();
        }
    }, []);

    /*
      API
    */
    const getSummary = async () => {
        console.log(`Fetching summary...`);
        setLoadingMessage('Fetching data...')
        if (api.idToken === null) {
            api.idToken = await firebaseUser.getIdToken();
            console.log(`Id Token: ${api.idToken}`)
        }
        try {
            setLoadingMessage('Loading dashboard...')
            const summary = await api.getSummary();
            if (summary) {
                console.log(`Got summary: setting data... ${JSON.stringify(summary)}`)
                setUser(summary.user);
                setProperties(summary.properties);
                setLoadingMessage(null);
            } else {
                throw new Error('Error fetching summary')
            }
        } catch (err) {
            setLoadingMessage('Error!' + err.message)
        }
    };

    return (
        <div>
            {loadingMessage && <Loading message={loadingMessage} />}
            {user &&
                <div>
                    <WelcomeBanner />
                    <Summary />
                    <CalendarWidget />
                </div>
            }

            {isExpensesPopupShown &&
                <div css={css`
                    height: 100%;
                    width: 100%;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    position: fixed;
                    z-index: 3;
                    background-color: #00000066;
                    display: flex;
                    `}>
                    <ExpensesPopup />
                </div>}

            {selectedRange &&
                <div css={css`
                    height: 100%;
                    width: 100%;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    position: fixed;
                    z-index: 3;
                    background-color: #00000066;
                    display: flex;
                `}>
                    <DateRangePopup dateRange={selectedRange} />
                </div>}
        </div>
    )
}