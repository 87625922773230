/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React from 'react';
import Slider from "react-slick";
import DownloadIcon from '../../../assets/icons/download.svg';
import ProjectedIncomeIcon from '../../../assets/icons/gridicons_line-graph.svg';
import UpcomingPayoutsIcon from '../../../assets/icons/mdi_bank-plus.svg';
import ReceivedIncomeIcon from '../../../assets/icons/mdi_bank-transfer-in.svg';
import ExpensesIcon from '../../../assets/icons/mdi_file-document.svg';
import useExpensesPopup from '../../../common/state/usePopup';
import { ImageButton } from '../../../components/Buttons';
import { Card } from '../../../components/Card';
import { Column, Row } from '../../../components/Layout';
import { H1, H4, P } from '../../../components/Text';

export const Summary = props => {

    const { setExpensesPopupShown } = useExpensesPopup();

    const settings = {
        dots: true,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 2
    };

    const summary = {
        "2018": [
            {
                icon: ReceivedIncomeIcon,
                color: '4A9D77',
                title: "Payouts received",
                value: "€19,829"
            },
            {
                icon: UpcomingPayoutsIcon,
                color: 'E95060',
                title: "Upcoming payouts",
                value: "€0"
            },
            {
                icon: ProjectedIncomeIcon,
                color: '363565',
                title: "Projected income",
                value: "€0"
            },
            {
                icon: ExpensesIcon,
                color: '00ADD6',
                title: "Expense invoiced",
                value: "4",
                onClick: () => setExpensesPopupShown(true)
            }
        ],
        "2019": [
            {
                icon: ReceivedIncomeIcon,
                color: '4A9D77',
                title: "Payouts received",
                value: "€19,829"
            },
            {
                icon: UpcomingPayoutsIcon,
                color: 'E95060',
                title: "Upcoming payouts",
                value: "€0"
            },
            {
                icon: ProjectedIncomeIcon,
                color: '363565',
                title: "Projected income",
                value: "€0"
            },
            {
                icon: ExpensesIcon,
                color: '00ADD6',
                title: "Expense invoiced",
                value: "4",
                onClick: () => setExpensesPopupShown(true)
            }
        ],
        "2020": [
            {
                icon: ReceivedIncomeIcon,
                color: '4A9D77',
                title: "Payouts received",
                value: "€19,829"
            },
            {
                icon: UpcomingPayoutsIcon,
                color: 'E95060',
                title: "Upcoming payouts",
                value: "€0"
            },
            {
                icon: ProjectedIncomeIcon,
                color: '363565',
                title: "Projected income",
                value: "€0"
            },
            {
                icon: ExpensesIcon,
                color: '00ADD6',
                title: "Expense invoiced",
                value: "4",
                onClick: () => setExpensesPopupShown(true)
            }
        ],
        "2021": [
            {
                icon: ReceivedIncomeIcon,
                color: '4A9D77',
                title: "Payouts received",
                value: "€19,829"
            },
            {
                icon: UpcomingPayoutsIcon,
                color: 'E95060',
                title: "Upcoming payouts",
                value: "€0"
            },
            {
                icon: ProjectedIncomeIcon,
                color: '363565',
                title: "Projected income",
                value: "€0"
            },
            {
                icon: ExpensesIcon,
                color: '00ADD6',
                title: "Expense invoiced",
                value: "4",
                onClick: () => setExpensesPopupShown(true)
            }
        ]
    }

    const currentYear = new Date().getFullYear().toString();

    return (
        <div css={css`margin: 0 2em;height: fit-content;`}>
            <Slider
                {...settings}>
                {Object.entries(summary).map(([key, value]) => (
                    <Column key={key} center>
                        <H4 css={css`margin: 1em;color: #A2A1BC;`}>{key}</H4>
                        <Row key={key} wrap spread>
                            {value.map(card => (
                                <Card key={card.title} css={css`
                                    flex-grow: 0.2;
                                    transition: all 0.2s ease-in;
                                    :hover {
                                        transform: scale(0.95);
                                        background-color: #FAFAFA;
                                        cursor: ${card.onClick ? 'pointer': 'normal'};
                                    }
                                `} onClick={card.onClick ? card.onClick : null}>
                                    {card.onClick && <ImageButton css={css`position: absolute; align-self: flex-end;`} src={DownloadIcon} />}
                                    <Column css={css`width: 100%;`} center>
                                        <div css={css`
                                            padding: 10px;
                                            border-radius: 30px;
                                            background-color: ${`#${card.color}66`};
                                        `}>
                                            <img src={card.icon} />
                                        </div>
                                        <P css={css`margin-top: 10px; color: #A2A1BC;`}>{card.title}</P>
                                        <H1 bold css={css`
                                        color: #363565;
                                    `}>{card.value}</H1>
                                        <P css={css`color: #A2A1BC;`} secondary>{currentYear === key ? "This year" : key}</P>
                                    </Column>
                                </Card>
                            ))}
                        </Row>
                    </Column>
                ))}
            </Slider>
        </div>
    )
}