import React from 'react'
import { WelcomeBanner } from './components/WelcomeBanner';
import useAccount from '../../common/state/useAccount';
import { Card } from '../../components/Card';
import { Column, Row } from '../../components/Layout';
import { H2, P, H5 } from '../../components/Text';

import DownloadIcon from '../../assets/icons/download.svg';

// below comment line is required
// it tells babel how to convert properly
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Attachment } from '../../components/Field';
import useData from '../../common/state/useData';

const Field = ({ label, text }) => {
    return (
        <Row between>
            <H5>{label}</H5>
            <P css={css`text-align:right;`}>{text}</P>
        </Row>
    )
}

const OwnerDetails = props => {

    const { user: ownerDetails } = useData();

    return (
        <Card css={css`
            flex: 2 1 0px;
            margin: 1em;
            min-width: 40%;
            `}>
            <Column css={css`width: 100%;`}>
                <H2>Owner details</H2>
                <P secondary>Details our team will use when contacting you.</P>
                <Field label="First name" text={ownerDetails.firstName} />
                <Field label="Last name" text={ownerDetails.lastName} />
                <Field label="Phone number" text={ownerDetails.phoneNumber} />
                <Field label="Email" text={ownerDetails.email} />
            </Column>
        </Card>
    )
}

const FiscalDetails = props => {

    const { user } = useData();
    const fiscalDetails = user.fiscalDetails;

    return (
        <Card css={css`
            flex: 2 1 0px;
            margin: 1em;
            min-width: 40%;
            `}>
            <Column css={css`width: 100%;`}>
                <H2>Fiscal details</H2>
                <P secondary>Details used when issuing commission invoices for our services</P>
                <Field label="Fiscal name" text={fiscalDetails.name} />
                <Field label="Passport / NIE / NIF / CIF" text={fiscalDetails.identifier} />
                <Field label="Fiscal address" text={fiscalDetails.address} />
            </Column>
        </Card>
    )
}

const PropertyDetails = props => {

    const { properties } = useData();

    // TODO: Check which property is selected and find it in the list
    // At this point just select the first one
    const propertyDetails = properties[0];

    return (
        <Card css={css`
            flex: 2 1 0px;
            margin: 1em;
            min-width: 40%;
            `}>
            <Column css={css`width: 100%;`}>
                <H2>Property details</H2>
                <P secondary>Basic details of your property.</P>
                <Field label="Propert Ref. Code" text={propertyDetails.rdrId} />
                <Field label="Property type" text={propertyDetails.type} />
                <Field label="Number of bedrooms" text={propertyDetails.numberOfBedrooms} />
                <Field label="Number of bathrooms" text={propertyDetails.numberOfBathrooms} />
                <Field label="Address" text={propertyDetails.address} />
            </Column>
        </Card>
    )
}

const BankDetails = props => {

    const { user } = useData();
    const bankDetails = user.bankDetails;

    return (
        <Card css={css`
            flex: 2 1 0px;
            margin: 1em;
            min-width: 40%;
            `}>
            <Column css={css`width: 100%;`}>
                <H2>Bank details</H2>
                <P secondary>Account to which we will send your rental share..</P>
                <Field label="Account holder name" text={bankDetails.accountName} />
                <Field label="BIC / SWIFT" text={bankDetails.bic} />
                <Field label="IBAN" text={bankDetails.iban} />
                <Field label="Bank name" text={bankDetails.bankName} />
                <Field label="Bank address" text={bankDetails.bankAddress} />
            </Column>
        </Card>
    )
}

const Documents = props => {

    const { user } = useData();

    const documents = []; // TODO

    return (
        <Card css={css`
            flex: 2 1 0px;
            margin: 1em;
            min-width: 40%;
            `}>
            <Column css={css`width: 100%;`}>
                <Row>
                    <H2>Documents</H2>
                    <img css={css`margin-left: 10px;`} src={DownloadIcon} />
                </Row>
                <P secondary>Here are your main account documents.</P>
                <Row css={css`margin-top: 1em;`} >
                    {documents.map(document => (
                        <Attachment attachment={document} />
                    ))}
                </Row>
            </Column>
        </Card>
    )
}

export default props => {
    return (
        <div>
            <WelcomeBanner />
            <Row wrap spread css={css`padding: 2em;box-sizing: border-box;`}>
                <OwnerDetails />
                <FiscalDetails />
                <PropertyDetails />
                <BankDetails />
                <Documents />
            </Row>
        </div>
    )
}
