import React, { useState } from 'react';
import { H1, H2, H3, P, Large } from '../../components/Text';
import { PrimaryButton } from '../../components/Buttons';
import {ClassicSpinner}  from 'react-spinners-kit';

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { TextInput, SubmitInput } from '../../components/Form';
import { Link } from 'react-router-dom';

import { Header } from '../../components/Header';

import logoFull from '../../assets/logo_header_full.png';
import illustration from '../../assets/welcome_bg.png';
import { Row, Column } from '../../components/Layout';
import { Card } from '../../components/Card';

import { useHistory } from 'react-router-dom';
import useSession from '../../common/state/useSession';
import useAuth, { InvalidAuthError, UserNotFoundError } from '../../common/state/useAuth';

export const LoginForm = props => {

  const { user, signin } = useAuth();

  const [isValid, setValid] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [errorText, setErrorText] = useState('');

  const onSubmit = async event => {
    event.preventDefault();
    setIsSigningIn(true);
    try {
      await signin(email , password);
      console.log('HMMMM');

      setIsSigningIn(false);
    } catch(error) {
      console.log(error);
      console.log('ERR');

      if (error instanceof InvalidAuthError) {
        setErrorText('Invalid email/password');
      } else if (error instanceof UserNotFoundError) {
        setErrorText('User not found, try again.')
      }
      setIsSigningIn(false);
    }
    console.log(`The user is ${user}`)

  }

  /**
   * @param {React.ChangeEvent<HTMLInputElement>} event
   */
  const onEmailUpdated = event => {
    console.log(`Email name updated to ${event.target.value}`);
    setEmail(event.target.value);
    setValid(event.target.value.length > 0);
  };

  /**
   * @param {React.ChangeEvent<HTMLInputElement>} event
   */
  const onPasswordUpdated = event => {
    console.log(`Passsword updated to ${event.target.value}`);
    setPassword(event.target.value);
    setValid(event.target.value.length > 0);
  };

  return (
    <form onSubmit={onSubmit} css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        `}>
      <TextInput type="email" placeholder="Email" onChange={onEmailUpdated} />
      <TextInput type="password" placeholder="Password" onChange={onPasswordUpdated} />
      <SubmitInput
        isValid={isValid}
        type="submit"
        value={isSigningIn ? 'Logging in...' : 'Login'}
      />
      {errorText.length > 0 && 
        <div css={css`padding: 5px 10px; color: #BA3F1D;`}>
          <P>{errorText}</P>
        </div>
      }
    </form>
  )
}

export const Login = props => {
  const history = useHistory();

  return (
    <div css={css`
            background-color: #009E93;
            height: 100%;
            width: 100%;
        `}>

      <Header css={css`background-color: white;`}>
        <Column left middle>
          <img css={css`height: 40px;`} src={logoFull} />
        </Column>
        <Column css={css`
          flex-grow: 1;
          `} right>
          <ul css={css`
            width: fit-content;
            list-style: none;
            align-self:flex-end;

            > li a {
              text-decoration: none;
              color: #00B8BC;

              :not(:first-of-type) {
                  margin-left: 10px;
              }
              :hover {
                  color: #2F6E72;
              }
              }
            `}>
            <li>
              <a href="https://reekanddalli.com">Home</a>
              <a href="https://reekanddalliproperties.com">All properties</a>
            </li>
          </ul>
        </Column>
      </Header>

      <div
        css={css`
                height: 100%;
                width: 100%;
                background-repeat: no-repeat;
            `}>
        <Row css={css`  
                `} evenly>
          <Column middle left>
            <H2 css={css`color: white;margin: 0;`}>Welcome to your</H2>
            <H1 css={css`color: white;margin: 0;`}>Dashboard</H1>
            <img src={illustration} />
          </Column>
          <Column css={css`
                        margin-top: 4em;
                        margin-right: 4em;
                    `}>
            <Card center>
              <LoginForm />
            </Card>
          </Column>
        </Row>
      </div>
    </div>
  );
}

export default Login;