import React from 'react';
/** @jsx jsx */
import { jsx, css, keyframes } from '@emotion/core';
import bannerIllustration from '../../../assets/home_illustration.png';
import useData from '../../../common/state/useData';
import { PrimaryButton } from '../../../components/Buttons';
import { Card } from '../../../components/Card';
import { Column, Row } from '../../../components/Layout';
import { H2, P } from '../../../components/Text';

export const WelcomeBanner = props => {
    const { user } = useData();
    return (<Card css={css`
            padding: 0;
            margin: 4em 2em 0 2em;
            position: relative; 
        `} shadow={false}>

        <div css={css`
            position: absolute; 
            top: 0; 
            bottom: 0;
            left: 0; 
            right: 0; 
            display: flex;
            justify-content: center;
            overflow: hidden;
            `}>
            <Blob />
            <Blob2 />
        </div>

        <Row css={css`z-index:2;`} spread wrap>

            <Column>
                <img css={css`
                            height: 230px;
                            margin-top: -40px;
                        `} src={bannerIllustration} />
            </Column>
            <Column css={css`padding: 1em;`} evenly>
                <div>
                    <H2>Hi! Welcome {user.firstName} 👋</H2>
                    <P css={css``} secondary>Keep up to date on your properties performance!</P>
                </div>
                <a css={css`width: fit-content;`} href="https://www.reekanddalli-properties.com/en/1783496/rdr158---seven-golden-mile">
                    <PrimaryButton round>View my property advert →</PrimaryButton>
                </a>
            </Column>

        </Row>
    </Card>);
};


const Blob = props => {

    const transform = keyframes`
      0%,
      100% { border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%; } 
      14% { border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%; } 
      28% { border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%; } 
      42% { border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%; } 
      56% { border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%; } 
      70% { border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%; } 
      84% { border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%; } 
    `;

    const movement = keyframes`
       0%,
       100% { transform: none; }
       50% { transform: translateY(5%) rotateY(10deg); }
    `;

    return (
        <div css={css`
        position: absolute;
        z-index: 2;
        display: block;
        margin: auto;
        align-self: center;
        min-height: 20%;
        min-width: 60%;
        width: 50%;
        height: 200%;  
        opacity: 0.3;
        background-color: #D8E5FF; 
        background-size: 1600% 1600%;
        transform-origin: 50% 50%;
        transform-style: preserve-3d;
        animation: ${transform} 25s ease-in-out infinite both alternate, ${movement} 15s ease-in-out infinite both;
      `}>
        </div>
    )
}

const Blob2 = props => {

    const transform = keyframes`
      0%,
      100% { border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%; } 
      14% { border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%; } 
      28% { border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%; } 
      42% { border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%; } 
      56% { border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%; } 
      70% { border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%; } 
      84% { border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%; } 
    `;

    const movement = keyframes`
       0%,
       100% { transform: none; }
       50% { transform: translateY(-5%) rotateY(-32deg); }
    `;

    return (
        <div css={css`
        position: absolute;
        z-index: 1;
        display: block;
        margin: auto;
        align-self: center;
        min-height: 15%;
        min-width: 70%;
        width: 50%;
        height: 200%;  
        opacity: 0.2;
        background-color: #9CECE6; 
        transform-origin: 50% 50%;
        transform-style: preserve-3d;
        animation: ${transform} 20s ease-in-out infinite both alternate, ${movement} 15s ease-in-out infinite both;
      `}>
        </div>
    )
}