import { getState } from "./Context";
import { User, Property } from 'rdd-shared';

const useData = () => {
    const [state, setState] = getState();

    /**
     * Set all from get summary.
     * @param {import("rdd-shared").Summary} summary The new summary 
     */
    const setSummary = summary => {
        console.log(`Setting summary. ${JSON.stringify(summary)}`)
        setState(state => ({ ...state, ...summary }));
    }

    const setUser = (user: User) => {
        setState(state => ({...state, data: {...state.data, user}}))
    }

    const setProperties = (properties: Array<Property>) => {
        setState(state => ({...state, data: {...state.data, properties}}))
    }

    return {
        // calendar: state.data.calendar,
        user: state.data.user,
        properties: state.data.properties,
        setUser,
        setProperties
    }
};

export default useData;