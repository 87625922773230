import { getState } from "./Context";

const useSession = () => {
  const [state, setState] = getState();

  /**
   * @param {boolean} loggedIn 
   */
  const setLoggedIn = loggedIn => {
    setState(state => ({ ...state, isLoggedIn: loggedIn }));
  }
  

  return {
    isLoggedIn: state.isLoggedIn,
    setLoggedIn
  }
};

export default useSession;