import React from 'react';

/** @jsx jsx */
import { jsx, css } from '@emotion/core';

export const Table = props => {
    return (<table {...props} />)
}

export const TableHeader = props => {
    return (<th css={css`text-align: left;`} {...props} />)
}

export const TableRow = props => {
    return (<tr css={css``} {...props} />)
}

export const TableCell = props => {
    return (<td {...props} />)
}