import React from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/core";

export const Row = allProps => {
  const { evenly, spread, between, center, left, right, middle, wrap, packed, ...props } = allProps;

  return (
    <div
      css={css`
        display: flex !important;
        flex-direction: row;
        width: 100%;
        ${packed ? `justify-content: packed;` : ``}
        ${evenly ? `justify-content: space-evenly;` : ``}
        ${spread ? `justify-content: space-around;` : ``}
        ${between ? `justify-content: space-between;` : ``}
        ${center ? `justify-content: center;` : ``}
        ${left ? `justify-content: flex-start;` : ``}
        ${right ? `justify-content: flex-end;` : ``}
        ${middle ? `align-items: center;` : ``}
        ${wrap ? `flex-wrap: wrap;` : ``}
      `}
      {...props}
    />
  );
}
export const Column = allProps => {
  const { center, left, right, middle, fit, evenly, ...props } = allProps;

  return (
    <div
      css={css`
        display: flex !important;
        flex-direction: column;
        width: auto;
        ${center ? `align-items: center;` : ``}
        ${left ? `align-content: left;` : ``}
        ${right ? `align-content: right;` : ``}
        ${middle ? `justify-content: center;`: ``}
        ${fit ? `height:fit-content;` : ``}
        ${evenly ? `justify-content: space-evenly;` : ``}
      `}
      {...props}
    />
  );
}