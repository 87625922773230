import { getState } from "./Context";

const useCalendar = () => {
    const [state, setState] = getState();

    /**
     * Set all from get summary.
     * @param {DateRange} selectedRange The new summary 
     */
    const setSelectedRange = selectedRange => {
        console.log(`Setting date range. ${JSON.stringify(selectedRange)}`)
        setState(state => ({ ...state, selectedRange }));
    }

    /**
     * Set the selected calendar year. 
     * @param {number} selectedYear The year
     */
    const setSelectedYear = selectedYear => {
        console.log(`Setting year. ${JSON.stringify(selectedYear)}`)
        setState(state => ({ ...state, selectedYear }));
    }

    return {
        calendar: state.data.calendar,
        selectedRange: state.selectedRange,
        selectedYear: state.selectedYear,
        setSelectedRange,
        setSelectedYear
    }
};

export default useCalendar;