import { getState } from "./Context";

const useExpensesPopup = () => {
  const [state, setState] = getState();

    /**
     * @param {boolean} isExpensesPopupShown 
     */
    const setExpensesPopupShown = isExpensesPopupShown => {
        setState(state => ({...state, isExpensesPopupShown: isExpensesPopupShown}));
    }

  return {
    isExpensesPopupShown: state.isExpensesPopupShown,
    setExpensesPopupShown
  }
};

export default useExpensesPopup;