/** @jsx jsx */
import { css, jsx } from "@emotion/core";

export const ButtonStyle = `
    padding: 1em 2em;
    background-color: #00B8BC;
    font-size: 10pt;
    border-radius: 4px;
    border: none;
    color: white;
    font-weight: bold;
    outline: none;
    width: fit-content;
    transition: background-color 0.2s ease-in;
    
    :hover {
        background-color: #007570;
        cursor: pointer;
    }
    :active {
        background-color: #07635A;
        transform: scale(0.95);
    }
    font-family: inherit;
`;

export const SmallButtonStyle = `
${ButtonStyle}
  padding: 0.3em 0.6em;
`;

export const PrimaryButton = allProps => {
  const {round,...props} = allProps;
  return (
    <button
      css={css`
    ${ButtonStyle}
    ${round ? `border-radius: 20px;` : ``}
  `}
      {...props} />
  )
}

export const SmallButton = allProps => {
  const {round,...props} = allProps;

  return (
    <button
      css={css`
      ${SmallButtonStyle}
      ${round ? `border-radius: 20px;` : ``}
    `}
      {...props} />
  )
}

export const ImageButton = allProps => {

  return (
    <img css={css`
      :hover {
        opacity: 0.5;
        transform: scale(0.8);
        cursor: pointer;
      }
    `}
     {...allProps}/>
  )
}