import { getState } from "./Context";

const useAccount = () => {
    const [state, setState] = getState();

    /**
     * Set all from get account.
     * @param {OwnerAccount} account The new account 
     */
    const setAccount = account => {
        console.log(`Setting account. ${JSON.stringify(account)}`)
        setState(state => ({ ...state, ...account }));
    }

    return {
        ownerDetails: state.account.ownerDetails,
        fiscalDetails: state.account.fiscalDetails,
        propertyDetails: state.account.propertyDetails,
        bankDetails: state.account.bankDetails,
        documents: state.account.documents,
        setAccount
    }
};

export default useAccount;