import React, { Suspense, lazy, useEffect, useState } from 'react'
import { Switch, Route, Redirect, Link, useLocation } from 'react-router-dom'
import useSession from '../common/state/useSession';
// Hooks
import useErrorBoundary from "use-error-boundary";

// Routes
const Home = lazy(() => import('./Home'));
const Account = lazy(() => import('./Account'));

// UI
import { motion, AnimatePresence } from "framer-motion";
import { Column, Row } from '../components/Layout';
import { Header } from '../components/Header';

// Assets 
import logo from './../assets/logo_header.png';

/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { P, H1 } from '../components/Text';
import useExpensesPopup from '../common/state/usePopup';
import { ExpensesPopup } from './Home/components/ExpensesPopup';
import useCalendar from '../common/state/useCalendar';
import { DateRangePopup } from './Home/components/DateRangePopup';
import { Loading } from '../components/Loading';
import { useAuth } from '../common/state/useAuth';
import Login from './Login';

import { api } from "../api/api";
import useData from '../common/state/useData';

const Footer = props => {

  return (
    <Row css={css`padding: 2em;box-sizing:border-box;`} center>
      <P secondary>© Reek & Dalli 2020.</P>
    </Row>
  )
}

export default function MainApp() {
  const { user: firebaseUser, signout } = useAuth();
  const { isLoggedIn, setLoggedIn } = useSession();
  const { selectedRange } = useCalendar();
  const location = useLocation();
  const { user, setUser, properties, setProperties } = useData();
  const [loadingMessage, setLoadingMessage] = useState('');

  const selectedProperty = properties ? properties[0] : null;

  // Motion transitions
  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 1.05
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 0.95
    }
  };

  const {
    ErrorBoundary, // class - The react component to wrap your children in. This WILL NOT CHANGE
    didCatch, // boolean - Whether the ErrorBoundary catched something
    error, // null or the error
    errorInfo // null or the error info as described in the react docs
  } = useErrorBoundary()

  const logout = async () => {
    await signout();
  }

  return (
    <div css={css`
      height: auto;
      width: 100%;
      background-color:#F8F8F8;
    `}>
      <div css={css`
      height: auto;
      width: 100%;
      max-width: 1250px;
      margin:auto;
    `}>
        <Header >
          <Row css={css`margin: 0 2em;`} middle between>
            <img css={css`height: 40px;`} src={logo} />
            <P bold css={css`color: #363565;`} >Owner Dashboard</P>
            {/* TODO: Property switcher */}
            <div css={css`
            background-color: white;
            border-radius: 10px;
            display: flex;
          `}>
              <Row center>
                <div css={css`padding: 15px 10px;`}><P secondary css={css`margin:0;font-size: 10pt;`}>Your property</P></div>
                <div css={css`padding: 15px 10px;border-left: 1px solid #F0F2F7;`}>
                  <P bold css={css`margin:0;font-size: 10pt;`}>{selectedProperty ? selectedProperty.rdrId : 'No property selected'}</P>
                  </div>
              </Row>
            </div>
            <ul css={css`
            width: fit-content;
            list-style: none;
            display: flex;
            align-self:flex-end;
                > li a {
                text-decoration: none;
                color: #535272;
                font-weight: bold;
                font-size: 10pt;
                  :hover {
                      color: #2F6E72;
                  }
                }
                > li {
                :not(:first-of-type) {
                    margin-left: 40px;
                }
               
            }
            `}>
              <li><Link to="/">Dashboard</Link></li>
              <li><Link to="/account">Account</Link></li>
              <li onClick={logout}><a href="">Logout</a></li>
            </ul>
          </Row>
        </Header>

        {/*
        Code-splitting.
        https://reactjs.org/docs/code-splitting.html#reactlazy

        We're lazy loading each component, and using a fallback when loading it.

        */}
        <ErrorBoundary>
          <div css={css`
            width: 100%;
            min-height: 100%;
          `}>
            {!didCatch && 
              <Suspense fallback={<Loading message={loadingMessage} />}>
              <AnimatePresence>
                <Switch location={location} key={location.pathname}>
                  <Route exact path="/account">
                    {
                      firebaseUser
                        ? <motion.div
                          initial="initial"
                          animate="in"
                          exit="out"  
                          transition={{ ease: "easeOut", duration: 0.2 }}
                          variants={pageVariants}>
                          <Account/>
                        </motion.div>
                        : <Redirect to="/login" />
                    }
                  </Route>
                  <Route exact path={['/', '/home']}>
                    {
                      firebaseUser
                        ? <motion.div
                        initial="initial"
                        animate="in"
                        exit="out"
                        transition={{ ease: "easeOut", duration: 0.2 }}
                        variants={pageVariants}><Home/>
                        </motion.div>
                        : <Redirect to="/login" />
                    }
                  </Route>
                  
                </Switch>
              </AnimatePresence>
            </Suspense>
          }
          {didCatch && <H1>{error}{errorInfo}</H1>}
          </div>
        </ErrorBoundary>

        <Footer />
      </div>
    </div>
  )
}
