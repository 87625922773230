
/**
 * @type {OwnerAccount}
 */
export const account = {
    ownerDetails: {
        firstName: "Jack",
        lastName: "Smith",
        phoneNumber: "+34 654 725 936",
        email: "jacksmith@gmail.com"
    },
    fiscalDetails: {
        name: "Invest Spain S.L",
        identifier: "X3950192V",
        address: "Harold Flats, Number 32, Kentish Town, NW51RH, London"
    },
    propertyDetails: {
        refCode: "RDR123",
        type: "Villa",
        numberOfBathrooms: 2,
        numberOfBedrooms: 2,
        address: "Casa Florida, Numero 43, Altos de Nagueles, 29602, Marbella, Malaga"
    },
    bankDetails: {
        accountName: "Mary Smith",
        bic: "BAC3746",
        iban: "ES78 9348 7845 8264",
        bankName: "Sabadell Solbank",
        bankAddress: "Banco Sabadell s/n, Centro Plaza, Nueva Andalucia, 29660, Marbella, Malaga"
    },
    documents: [
        {
            name: "Landlord contract",
            url: "https://bbc.co.uk"
        },
        {
            name: "Owner ID",
            url: "https://bbc.co.uk"
        },
        {
            name: "Rental license",
            url: "https://bbc.co.uk"
        },
        {
            name: "Key handover",
            url: "https://bbc.co.uk"
        },
        {
            name: "Police authorisation",
            url: "https://bbc.co.uk"
        }
    ]
} 