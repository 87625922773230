import React, { useState } from 'react'
import { Column, Row } from '../../../components/Layout'
import { H1, P, H4 } from '../../../components/Text'
import DownloadIcon from '../../../assets/icons/download.svg';
import { Card } from '../../../components/Card';
import CloseIcon from '../../../assets/icons/close.svg';
import CalendarIcon from '../../../assets/icons/calendar.svg';
import TenantIcon from '../../../assets/icons/tenant.svg';
import NightIcon from '../../../assets/icons/night.svg';
import EuroIcon from '../../../assets/icons/euro.svg';
import TransferIcon from '../../../assets/icons/transfer.svg';
import AttachmentIcon from '../../../assets/icons/attachment.svg';

/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import useExpensesPopup from '../../../common/state/usePopup';
import { ImageButton } from '../../../components/Buttons';
import { Table, TableRow, TableCell, TableHeader } from '../../../components/Table';
import useCalendar from '../../../common/state/useCalendar';
import moment from 'moment';
import { Field, Attachment } from '../../../components/Field';

const TextBox = ({text, onChange}) => (
    <input type="text" value={text} placeholder="No comments" css={css`
        padding: 10px; 
        border-radius: 10px;
         border: 2px solid #D9D9D9;
         outline: none;
         font-size: 10pt;
         margin: 1em;
        `}
        onChange={onChange}/>
)

/**
 * Expenses Popup
 * @param {{dateRange: BookedDateRange}} param0 
 */
export const BookedDateRangePopup = ({ dateRange }) => {

    const numberOfNights = Math.abs(moment(dateRange.startDate, "YYYY-MM-DD").diff(moment(dateRange.endDate, "YYYY-MM'DD"), 'days'));

    const [editedComment, setEditedComment] = useState(dateRange.comment);
    const onCommentUpdated = (event) => {
        setEditedComment(event.target.value);
    }

    return (
        <Column>
            <Row middle>
                <H1>{dateRange.name}</H1>
                <P css={css`margin-left: 15px;`} secondary>{dateRange.id}</P>
            </Row>
            <Row>
                <Column>
                    <Field icon={CalendarIcon} label="Checkin date" text={dateRange.startDate} />
                    <Field icon={CalendarIcon} label="Checkout date" text={dateRange.endDate} />
                    <Field icon={CalendarIcon} label="Booked" text={dateRange.bookedDate} />
                    <Field icon={NightIcon} label="Number of nights" text={numberOfNights} />
                    <TextBox text={editedComment} onChange={onCommentUpdated} />
                </Column>
                <Column>
                    <Field icon={TenantIcon} label="Tenant name" text={dateRange.name} />
                    <Field icon={TenantIcon} label="Number of guests" text={dateRange.numberOfGuests} />
                    <Field icon={EuroIcon} label="Owner share" text={dateRange.ownerShare} />
                    <Field icon={TransferIcon} label="Estimated payout date" text={dateRange.payoutDate} />
                    <Field icon={AttachmentIcon} label="Attachments" />
                    {dateRange.attachments.map(attachment=>(
                        <Attachment attachment={attachment} />
                    ))}
                </Column>
            </Row>
        </Column>
    )
}

/**
 * Expenses Popup
 * @param {{dateRange: OwnerStayDateRange}} param0 
 */
export const OwnerStayDateRangePopup = ({ dateRange }) => {

    return (
        <Row>
            <Column>
                <H1>{dateRange.startDate} {dateRange.endDate}</H1>
                <H1>{dateRange.startDate} {dateRange.endDate}</H1>
                <P>Range</P>
            </Column>
        </Row>
    )
}

/**
 * Expenses Popup
 * @param {{dateRange: BlockedDateRange}} param0 
 */
export const BlockedDateRangePopup = ({ dateRange }) => {

    return (
        <Row>
            <Column>
                <H1>{dateRange.startDate} {dateRange.endDate}</H1>
                <H1>{dateRange.startDate} {dateRange.endDate}</H1>
                <P>Range</P>
            </Column>
        </Row>
    )
}

/**
 * Expenses Popup
 * @param {{dateRange: DateRange}} param0 
 */
export const DateRangePopup = ({ dateRange }) => {
    const { setSelectedRange } = useCalendar();

    const type = dateRange.type;

    return (
        <Card css={css`margin: auto;max-width: 70%;padding: 3em;`}>
            <ImageButton onClick={() => setSelectedRange(undefined)} css={css`position: absolute; align-self: flex-end;`} src={CloseIcon} />
            {type === 'booked' && <BookedDateRangePopup dateRange={dateRange} />}
            {type === 'ownerStay' && <BlockedDateRangePopup dateRange={dateRange} />}
            {type === 'blocked' && <BlockedDateRangePopup dateRange={dateRange} />}
        </Card>
    )
}