/**
 * @type {import("rdd-shared").Summary}
 */
export const summary = {
  calendar: {
    booked: [
      {
        id: 'RDR2347392256',
        type: 'booked',
        bookedDate: '2019-11-11',
        startDate: "2020-01-14",
        endDate: "2020-01-24",
        name: "Pedro Gutierrez",
        numberOfGuests: 2,
        ownerShare: "€2,540.00",
        payoutDate: "2020-02-20",
        attachments: [
          {
            name: 'Tenant contract', 
            url: "https://drive.google.com/open?id=1fBhQUIPDTi1U6oh2pkKMPuDLMlGOFI4l68V2rs0sKQA"
        }],
        comment: "Test"
      },
      {
        id: 'RDR2047393756',
        type: 'booked',
        bookedDate: '2019-12-15',
        startDate: "2020-01-24",
        endDate: "2020-02-07",
        name: "Pablo Motos",
        numberOfGuests: 2,
        ownerShare: "€2,540.00",
        payoutDate: "2020-02-20",
        attachments: [],
        comment: "Test"
      },
      {
        id: 'RDR2047592756',
        type: 'booked',
        bookedDate: '2019-11-20',
        startDate: "2020-02-08",
        endDate: "2020-02-10",
        name: "Armin Halls",
        numberOfGuests: 2,
        ownerShare: "€2,540.00",
        payoutDate: "2020-02-20",
        attachments: [],
        comment: "Test"
      },
      {
        id: 'RDR2047592799',
        type: 'booked',
        bookedDate: '2019-11-28',
        startDate: "2020-07-14",
        endDate: "2020-07-16",
        name: "Bob Bob",
        numberOfGuests: 2,
        ownerShare: "€2,540.00",
        payoutDate: "2020-02-20",
        attachments: [],
        comment: "Test"
      },
      {
        id: 'RDR2047592736',
        type: 'booked',
        bookedDate: '20120-02-20',
        startDate: "2020-07-16",
        endDate: "2020-07-20",
        name: "Alice Bob",
        numberOfGuests: 2,
        ownerShare: "€2,540.00",
        payoutDate: "2020-02-20",
        attachments: [],
        comment: "Test"
      },
    ],
    ownerStay: [
      {
        type: 'ownerStay',
        startDate: "2020-03-01",
        endDate: "2020-03-07",
        name: "Jesus Verdes"
      },
      {
        type: 'ownerStay',
        startDate: "2020-04-01",
        endDate: "2020-05-01",
        name: "Jesus Verdes"
      }
    ],
    blocked: [
      {
        type: 'blocked',
        startDate: "2020-01-01",
        endDate: "2020-01-14",
        name: "Jesus Verdes"
      },
      {
        type: 'blocked',
        startDate: "2020-06-05",
        endDate: "2020-06-21",
        name: "Jesus Verdes"
      }
    ]
  }
}