import React from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/core";

export const Row = props => (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        width: 100%;
        ${props.spread ? `justify-content: space-around;` : ``}
        ${props.center ? `justify-content: center` : ``}
        ${props.left ? `justify-content: left` : ``}
      `}
      {...props}
    />
  );

export const Column = props => (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        width: auto;
        ${props.center ? `align-content: center` : ``}
        ${props.left ? `align-content: left` : ``}
        ${props.right ? `align-content: right` : ``}
        ${props.middle ? `justify-content: center`: ``}
      `}
      {...props}
    />
  );