import * as firebase from 'firebase';
import 'firebase/auth';
import { node } from 'prop-types';

var app = null;

if (process.env.ENV === 'prod') {
  // Add your Firebase credentials
  app = firebase.initializeApp({
    apiKey: "AIzaSyDu1_LXvD1b1aftU8pfUtUeaoRWa_j_lyg",
    authDomain: "rd-owner-portal.firebaseapp.com",
    databaseURL: "https://rd-owner-portal.firebaseio.com",
    projectId: "rd-owner-portal",
    storageBucket: "rd-owner-portal.appspot.com",
    messagingSenderId: "397628344793",
    appId: "1:397628344793:web:b5fcd48c781bdf6871f1c8"
  });
} else {
  app = firebase.initializeApp({
      apiKey: "AIzaSyCaLOkYwobGClJTCNL4w4fMmKZV5XOFYy4",
      authDomain: "rd-owner-portal-dev.firebaseapp.com",
      databaseURL: "https://rd-owner-portal-dev.firebaseio.com",
      projectId: "rd-owner-portal-dev",
      storageBucket: "rd-owner-portal-dev.appspot.com",
      messagingSenderId: "356855356948",
      appId: "1:356855356948:web:eecb66fb99147b6e571101"
  });
}

export { app }