/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React, { useState } from 'react'
import { Row, Column } from '../../../components/Layout'
import LeftArrowIcon from '../../../assets/icons/arrow-left.svg';
import RightArrowIcon from '../../../assets/icons/arrow-right.svg';
import { H2, P } from '../../../components/Text';
import { Card } from '../../../components/Card';
import { ImageButton } from '../../../components/Buttons';
import { MonthView } from 'react-calendar';
import _ from 'lodash';

import moment from 'moment';

import useData from '../../../common/state/useData';
import useCalendar from '../../../common/state/useCalendar';

const FilterButton = props => {

    return (
        <div css={css`
            margin-left: 10px;
            padding: 5px 10px;
            display: flex;
            border-radius: 5px;
            border: 2px solid ${props.selected ? 'black' : '#EBEBEB'};
            justify-content: packed;
            align-items: center;
            transition: all 0.1s ease-in;
            
            :hover {
                cursor: pointer;
                background-color: #EBEBEB66;
            }
            :active {
                background-color: #C3C3C3;
            }
        `}
            {...props}>
            <P>{props.text}</P>
            <div css={css`
                margin-left: 5px;
                height: 15px;
                width: 15px;
                border-radius: 30px;
                background-color: ${props.color ? props.color : 'none'};
                border: ${props.color ? 'none' : '1px black solid'};
            `} />
        </div>
    )
}

export const CalendarWidget = props => {

    const [year, setYear] = useState(2020);

    const { calendar } = useCalendar();
    const years = [2018, 2019, 2020, 2021];

    moment.locale('es');

    /*
    Filtered calendar
    */
    const [filter, setFilter] = useState('');

    const filteredCalendar = _.cloneDeep(calendar);
    const totals = { booked: 0, ownerStay: 0, blocked: 0 };

    for (const cal of Object.keys(filteredCalendar)) {
        if (filter.length > 0 && cal !== filter) {
            console.log('Setting filter...')
            delete filteredCalendar[cal];
        }
    }
    
    for (const cal of Object.keys(calendar)) {
        if (calendar[cal] !== undefined) {
            totals[cal] += calendar[cal].reduce((totalDays, currentRange) => {
                return totalDays + Math.abs(moment(currentRange.startDate, "YYYY-MM-DD").diff(moment(currentRange.endDate, "YYYY-MM'DD"), 'days'));
            }, 0);
        }
    }

    const setSelectedFilter = selectedFilter => {
        if (selectedFilter === filter) {
            setFilter('');
        } else {
            setFilter(selectedFilter);
        }
    }


    const onNextYearSelected = () => {
        const currentYearIndex = years.findIndex(y => y === year);
        setYear(years[Math.min(years.length - 1, currentYearIndex + 1)]);
    }

    const onPreviousYearSelected = () => {
        const currentYearIndex = years.findIndex(y => y === year);
        setYear(years[Math.max(0, currentYearIndex - 1)]);
    }


    return (
        <Card css={css`
            padding: 2em;
            margin: 4em 2em 0 2em;
        `} shadow={false}>
            <Column>

                {/* Header */}
                <Row>
                    {/* Year switcher */}
                    <Row css={css`flex-grow: 1; flex-basis: 0;`} packed>
                        <H2>Calendar</H2>
                        <ImageButton onClick={onPreviousYearSelected} src={LeftArrowIcon} />
                        <H2>{year}</H2>
                        <ImageButton onClick={onNextYearSelected} src={RightArrowIcon} />
                    </Row>
                    <Row css={css`flex-grow: 3; flex-basis: 0;`} right>
                        <FilterButton selected={filter === 'booked'} text={`Booked (${totals.booked} days)`} color="#FFBABA" onClick={() => setSelectedFilter('booked')} />
                        <FilterButton selected={filter === 'ownerStay'} text={`Owner stay (${totals.ownerStay} days)`} color="#B5B5FF" onClick={() => setSelectedFilter('ownerStay')} />
                        <FilterButton selected={filter === 'blocked'} text={`Blocked (${totals.blocked} days)`} color="#898989" onClick={() => setSelectedFilter('blocked')} />
                        <FilterButton selected={filter === 'available'} text={`Available (${365 - totals.booked - totals.blocked - totals.ownerStay} days)`} onClick={() => setSelectedFilter('available')} />
                    </Row>
                </Row>

                <Row css={css`margin-top: 2em;`} wrap>
                    {/* Calendar */}
                    <Month year={year} month={0} filter={filter} />
                    <Month year={year} month={1} filter={filter} />
                    <Month year={year} month={2} filter={filter} />
                    <Month year={year} month={3} filter={filter} />
                    <Month year={year} month={4} filter={filter} />
                    <Month year={year} month={5} filter={filter} />
                    <Month year={year} month={6} filter={filter} />
                    <Month year={year} month={7} filter={filter} />
                    <Month year={year} month={8} filter={filter} />
                    <Month year={year} month={9} filter={filter} />
                    <Month year={year} month={10} filter={filter} />
                    <Month year={year} month={11} filter={filter} />
                </Row>
            </Column>
        </Card>
    )
}


/**
 * 
 * @param {{month: number, year: number, filter: string}} param0 
 */
const Month = ({ month, year, filter }) => {

    const { calendar, setSelectedRange } = useCalendar();

    const monthDate = moment().set('year', year).set('month', month).set('date', 1);
    const RangeMiddleSquare = allProps => {
        const { color, start, end, ...props } = allProps;

        return (

            <div css={css`
                position: absolute;
                display: flex;
                width: 100%;
                height: 100%;
                border-radius: ${start ? '3px 0px 0px 3px' : end ? '0px 3px 3px 0px' : '0'};
                background-color: ${`${color}66`};
                `} />
        )
    }

    const RangeStartTriangle = allProps => {
        const { color, ...props } = allProps;

        return (

            <div css={css`
                position: absolute;
                display: flex;
                width: 0;
                height: 0;
                left: 1px;
                border-bottom: 30px solid ${color};
                border-left: 36px solid transparent;
                `} />
        )
    }

    const RangeEndTriangle = allProps => {
        const { color, ...props } = allProps;

        return (

            <div css={css`
            position: absolute;
            display: flex;
            width: 0;
            height: 0;
            right: 3px;
            border-top: 30px solid ${color};
            border-right: 36px solid transparent;
            `} />
        )
    }

    const TodayCircle = allProps => {
        const { color, ...props } = allProps;

        return (
            <div css={css`
                position: absolute;
                display: flex;
                width: 80%;
                height: 80%;
                align-self: center;
                box-shadow: rgba(0%, 0%, 0%, 0.3) 2px 2px 5px;
                background: #009E93;
                border-radius: 20px;
            `} />
        )
    }

    const onRangeSelected = (date) => {
        console.log('hover');

        var foundRange;
        for (const range of calendar.booked) {
            if (moment(date).isBetween(range.startDate, range.endDate, 'day', '[]')) {
                foundRange = range;
            }
        }
        for (const range of calendar.ownerStay) {
            if (moment(date).isBetween(range.startDate, range.endDate, 'day', '[]')) {
                foundRange = range;
            }
        }
        for (const range of calendar.blocked) {
            if (moment(date).isBetween(range.startDate, range.endDate, 'day', '[]')) {
                foundRange = range;
            }
        }

        if (foundRange) setSelectedRange(foundRange);
    }

    return (
        <div key={month} css={css`padding: 0.5em; `}>
            <P bold>{monthDate.format('MMM')}</P>

            <MonthView
                activeStartDate={monthDate.toDate()}
                tileContent={
                    ({ date, view }) => {

                        var day = moment(date);
                        var startType = 'available';
                        var endType = 'available';

                        var isStart = false;
                        var isEnd = false;

                        var isBeforeToday = day.isBefore(moment(), 'day');
                        var isAfterToday = day.isAfter(moment(), 'day');
                        var isToday = day.isSame(moment(), 'day');

                        var isSunday = day.isoWeekday() === 7;
                        var isMonday = day.isoWeekday() === 1;

                        for (const [name, cal] of Object.entries(calendar)) {
                            for (const range of cal) {

                                const startDate = moment(range.startDate, "YYYY-MM-DD");
                                const endDate = moment(range.endDate, "YYYY-MM-DD");

                                if (day.isBetween(startDate, endDate, 'day', '()')) {
                                    startType = name;

                                }

                                if (day.isSame(startDate, 'day')) {
                                    isStart = true;
                                    startType = name;
                                }
                                if (day.isSame(endDate, 'day')) {
                                    isEnd = true;
                                    endType = name;
                                }
                            }
                        }

                        var startColor = startType === 'booked' ? '#FFBABA' : startType === 'ownerStay' ? '#B5B5FF' : startType === 'blocked' ? '#898989' : undefined
                        var endColor = endType === 'booked' ? '#FFBABA' : endType === 'ownerStay' ? '#B5B5FF' : endType === 'blocked' ? '#898989' : undefined

                        var showStart = false;
                        if (startType === filter || filter === '') {
                            showStart = true;
                        }

                        var showEnd = false;
                        if (endType === filter || filter === '') {
                            showEnd = true;
                        }

                        return (
                            <div css={css`
                            display: flex;
                            position: relative;
                            justify-content: center;
                            `}>
                                <P css={css`
                                width: 100%;
                                margin: 0;
                                padding: 7px 5px;
                                z-index: 3;
                                color: ${isBeforeToday ? 'rgb(143, 143, 143)' : isToday ? 'white' : 'black'};
                                `} onClick={() => onRangeSelected(date)}>{date.getDate()}</P>

                                {showStart && !isStart && !isEnd && isMonday && <RangeMiddleSquare start color={startColor} />}
                                {showStart && !isStart && !isEnd && isSunday && <RangeMiddleSquare end color={startColor} />}
                                {showStart && !isStart && !isEnd && !(isSunday || isMonday) && <RangeMiddleSquare color={startColor} />}
                                {showStart && isStart && <RangeStartTriangle color={startColor} />}
                                {showEnd && isEnd && <RangeEndTriangle color={endColor} />}
                                {showStart && isToday && <TodayCircle />}

                            </div >
                        )
                    }
                }
            />
        </div >
    )
}