import React, { useState, useEffect } from 'react';
import { H1, H2, H3, P, Large } from '../../components/Text';
import { PrimaryButton } from '../../components/Buttons';
import { ClassicSpinner } from 'react-spinners-kit';

/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { TextInput, SubmitInput } from '../../components/Form';
import { Link, useLocation } from 'react-router-dom';

import { Header } from '../../components/Header';

import logoFull from '../../assets/logo_header_full.png';
import illustration from '../../assets/welcome_bg.png';
import { Row, Column } from '../../components/Layout';
import { Card } from '../../components/Card';

import { useHistory } from 'react-router-dom';
import useSession from '../../common/state/useSession';
import useAuth, { InvalidAuthError, UserNotFoundError } from '../../common/state/useAuth';

import qs from 'qs';

export const RegisterForm = props => {

  const { user, signup } = useAuth();

  const [isValid, setValid] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [errorText, setErrorText] = useState('');

  useEffect(() => {

    if (props.email) {
      setEmail(props.email);
    }

    validate();
  });

  const onSubmit = async event => {
    event.preventDefault();
    setIsSigningIn(true);
    try {
      await signup(email, password);

      setIsSigningIn(false);
    } catch (error) {
      console.log(error);
      console.log('ERR');

      if (error instanceof InvalidAuthError) {
        setErrorText('Invalid email/password');
      } else if (error instanceof UserNotFoundError) {
        setErrorText('User not found, try again.')
      }
      setIsSigningIn(false);
    }
    console.log(`The user is ${user}`)

  }

  const validate = () => {
    setValid(email.length > 0 && password.length > 0 && repeatPassword.length > 0 && repeatPassword === password);
  }

  /**
   * @param {React.ChangeEvent<HTMLInputElement>} event
   */
  const onEmailUpdated = event => {
    setEmail(event.target.value);
  };

  /**
   * @param {React.ChangeEvent<HTMLInputElement>} event
   */
  const onPasswordUpdated = event => {
    setPassword(event.target.value);
  };

  /**
   * @param {React.ChangeEvent<HTMLInputElement>} event
   */
  const onRepeatPasswordUpdated = event => {
    setRepeatPassword(event.target.value);
  };

  return (
    <form onSubmit={onSubmit} css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        `}>
      <TextInput type="email" placeholder="Email" disabled={props.email} value={props.email} onChange={onEmailUpdated} />
      <TextInput type="password" placeholder="Password" onChange={onPasswordUpdated} />
      <TextInput type="password" placeholder="Repeat password" onChange={onRepeatPasswordUpdated} />
      <SubmitInput
        isValid={isValid}
        type="submit"
        value={isSigningIn ? 'Registering in...' : 'Create account'}
      />
      {errorText.length > 0 &&
        <div css={css`padding: 5px 10px; color: #BA3F1D;`}>
          <P>{errorText}</P>
        </div>
      }
    </form>
  )
}

export const Register = props => {
  const history = useHistory();
  const location = useLocation();

  const parsedEmail = qs.parse(location.search, { ignoreQueryPrefix: true }).email;

  return (
    <div css={css`
            background-color: #F5F5F5;
            height: 100%;
            width: 100%;
        `}>

      <Header css={css`background-color: white;`}>
        <Column left middle>
          <img css={css`height: 40px;`} src={logoFull} />
        </Column>
        <Column css={css`
          flex-grow: 1;
          `} right>
          <ul css={css`
            width: fit-content;
            list-style: none;
            align-self:flex-end;

            > li a {
              text-decoration: none;
              color: #00B8BC;

              :not(:first-of-type) {
                  margin-left: 10px;
              }
              :hover {
                  color: #2F6E72;
              }
              }
            `}>
            <li>
              <Link to="/login">Login</Link>
              <a href="https://reekanddalli.com">R&D Website</a>
            </li>
          </ul>
        </Column>
      </Header>

      <div
        css={css`
                height: 100%;
                width: 100%;
                background-repeat: no-repeat;
            `}>
        <Row css={css`  
                `} evenly>
          {/* <Column center>
            <H2 css={css`color: white;margin: 0;`}>Register</H2>
            <img src={illustration} />
          </Column> */}
          <Column center css={css`
                        margin-top: 4em;
                        margin-right: 4em;
                    `}>
            <Card center>
              <Column center>
                <H3>Create account</H3>

                <P secondary>Register for the Reek & Dalli Owner dashboard</P>
                <RegisterForm email={parsedEmail} />
              </Column>
            </Card>
          </Column>
        </Row>
      </div>
    </div>
  );
}

export default Register;