
/** @jsx jsx */
import { jsx, css } from '@emotion/core'

export const H1 = allProps => {
    const { secondary, bold, ...props } = allProps;
    return (
        <h1 css={css`
            margin: 5px 0;
            color: black;
    `}
            {...props} />
    );
}

export const H2 = allProps => {
    const { secondar, bold, ...props } = allProps;
    return (
        <h2 css={css`
        margin: 5px 0;
        color: ${props.secondary ? 'grey' : 'black'};
    `} {...props} />
    );
}

export const H3 = allProps => {
    const { secondary, bold, ...props } = allProps;
    return (

        <h3 css={css`
        margin: 5px 0;
        color: ${props.secondary ? 'grey' : 'black'};
    `} {...props} />
    );
}

export const H4 = allProps => {
    const { secondary, bold, ...props } = allProps;
    return (
        <h4 css={css`
            margin: 5px 0;
            color: black;
    `} {...props} />
    );
}

export const H5 = allProps => {
    const { secondary, bold, ...props } = allProps;
    return (
        <h5 css={css`
            margin: 5px 0;
            color: black;
    `} {...props} />
    );
}

export const P = allProps => {
    const { secondary, bold, ...props } = allProps;
    return (

        <p css={css`
        margin: 5px 0;
        color: ${secondary ? 'grey' : 'black'};
        font-weight: ${ bold ? 'bold' : 'normal'};
     `} {...props} />
    );
}

export const Large = allProps => {
    const { secondary, bold, ...props } = allProps;
    return (
        <p css={css`
        font-size: large;
        display: inline;
        color: ${secondary ? 'grey' : 'black'};
        font-weight: ${ bold ? 'bold' : 'normal'};
     `} {...props} />
    )
}