import dotenv from 'dotenv/config'

import React, { useState, useEffect, useContext } from "react";
import { RDProvider, RDContext } from "./common/state/Context";
import "regenerator-runtime/runtime";
import { BrowserRouter as Router, Route, Switch, Redirect, Link } from 'react-router-dom';
import ReactDOM from "react-dom";

// CSS
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "./styles.css";

// routes
import Login from "./routes/Login";
import Home from "./routes/Home";
import Account from "./routes/Account";

// components
import { Loading } from "./components/Loading";
import { CircleSpinner } from "react-spinners-kit";

import { Header } from './components/Header';

import logoFull from './assets/logo_header_full.png';
import logo from './assets/logo_header.png';

/** @jsx jsx */
import { css, jsx } from "@emotion/core";

// notifications
import { ToastProvider, useToasts } from "react-toast-notifications";

// hooks
import useLoading from "./common/state/useLoading";

import { api } from "./api/api";
import useSession from "./common/state/useSession";
import useData from "./common/state/useData";
import useToast from "./common/state/useToast";
// Header 
import { Column } from "./components/Layout";
import MainApp from "./routes/MainApp";
import useAuth from "./common/state/useAuth";
import Register from './routes/Register';


// Firebase
const App = () => {
  const { user } = useAuth();

  return <div>
    <div css={css`
      height: 100%;
      `}>
      <Switch>
        <Route path="/login">
          {
            !user
              ? <Login />
              : <Redirect to="/home" />
          }
        </Route>
        <Route path ="/register">
          {
            !user
              ? <Register />
              : <Redirect to="/home" />
          }
        </Route>
        <Route path="/">
          {
            user
              ? <MainApp />
              : <Redirect to="/login" />
          }
        </Route>
      </Switch>
    </div>
  </div>
};

function injectApp() {
  const newDiv = document.createElement("div");
  newDiv.setAttribute("id", "dashboardApp");
  document.body.appendChild(newDiv);
  ReactDOM.render(

    <Router>
      <RDProvider>
        <App />
      </RDProvider>
    </Router>,
    newDiv
  );
}

window.onload = () => injectApp();


