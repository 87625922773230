import { getState } from "./Context";

const useLoading = () => {
  const [state, setState] = getState();

    /**
     * @param {boolean} loading 
     */
    const setLoading = loading => {
        setState(state => ({...state, isLoading: loading}));
    }

    /**
     * @param {boolean} init 
     */
    const setInit = init => {
        setState(state => ({...state, isInit: init}));
    }

  return {
      isLoading: state.isLoading,
      isInit: state.isInit,
      setInit,
      setLoading
  }
};

export default useLoading;