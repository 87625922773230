import React from 'react';
import { P } from './Text';
import { DominoSpinner } from 'react-spinners-kit';

/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Column } from '../common/Layout';

/**
 * Loading indicator (full screen)
 * @param {{message?: string}} props
 */
export const Loading = ({message}) => (
    <div css={css`
        display: flex;
        position: absolute;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
    `}>
        <Column center>
            <DominoSpinner />
            <P css={css`text-align: center;`} secondary>Loading</P>
            {message && <P secondary>{message}</P>}
        </Column>
    </div>
);
