import React from 'react';

/** @jsx jsx */
import { jsx, css } from '@emotion/core'

export const Card = allProps => {
    const { center, shadow, ...props } = allProps;
    return (
      <div css={css`
              background: white;
              border-radius: 10px;
              box-shadow: ${shadow ? `2px 2px 20px #86868666` : `none`};
              display: flex;
              padding: 2em;
              flex-direction: column;
              align-items: ${center ? 'center' : 'start'};
              `}
        {...props} />
    )
}
  